import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { TradeInfoModalContext } from '../../TradeInfoModalContext'
import { useNotifications } from 'shared/Notifications'
import { addMediaToNote, createNote } from 'core/api/trades'
import { AxiosError } from 'axios'
import { Note } from 'core/types'
import { v4 as uuid } from 'uuid'

const FILE_PARAMS = {
  maxSize: 5 * 1024 * 1024,
  allowedFormats: ['image/jpeg', 'image/png'],
}

type UploadingItem = { file: File; startTimestamp: number; progress?: number | null; id: string }
type UseAttachmentUploadingConfig = {
  onMediaUpdate: () => Promise<unknown>
  onTradeUpdate: () => Promise<unknown>
}
export function useAttachmentUploading({
  onMediaUpdate,
  onTradeUpdate,
}: UseAttachmentUploadingConfig) {
  const { tradeItem } = useContext(TradeInfoModalContext)
  const [uploadingMap, setUploadingMap] = useState<Record<string, UploadingItem>>({})
  const uploadingList = useMemo(
    () => Object.values(uploadingMap).sort((a, b) => a.startTimestamp - b.startTimestamp),
    [uploadingMap]
  )
  const { showNotification } = useNotifications()

  const addFileToMap = useCallback(
    (file: File, id: string) => {
      if (uploadingMap[id]) throw new Error('File already exists in uploading map')
      const timestamp = Date.now()
      const entry: UploadingItem = {
        file,
        id,
        startTimestamp: timestamp,
        progress: undefined,
      }

      setUploadingMap((map) => ({ ...map, [id]: entry }))
    },
    [uploadingMap]
  )

  useEffect(() => console.log('uploadingMap', uploadingMap), [uploadingMap])

  const updateProgress = useCallback((uploadingEntryId: string, progress?: number | null) => {
    setUploadingMap((oldMap) => ({
      ...oldMap,
      [uploadingEntryId]: { ...oldMap[uploadingEntryId], progress } satisfies UploadingItem,
    }))
  }, [])

  const removeFileFromMap = useCallback((uploadingEntryId: string) => {
    setUploadingMap((map) => {
      const { [uploadingEntryId]: _, ...rest } = map
      return rest
    })
  }, [])

  const uploadImage = useCallback(
    async (file: File) => {
      const uploadingEntryId = uuid()

      try {
        addFileToMap(file, uploadingEntryId)
        let currentNote: Note
        // If note already exists, use it, otherwise create a new one
        if (tradeItem.note) {
          currentNote = tradeItem.note
        } else {
          const response = await createNote(tradeItem.id)
          currentNote = response.data.result
        }

        await addMediaToNote(currentNote.id, file, (ev) =>
          updateProgress(uploadingEntryId, ev.progress ? ev.progress * 100 : null)
        )
        if (!tradeItem.note) await onTradeUpdate()
        await onMediaUpdate()
      } catch (err) {
        console.error(err)
        if (err instanceof AxiosError && err.response?.data) {
          showNotification({
            text: err.response.data.message ?? 'Failed to upload image',
            type: 'error',
          })
        } else {
          showNotification({
            text: 'Failed to upload image',
            type: 'error',
          })
        }
      } finally {
        removeFileFromMap(uploadingEntryId)
      }
    },
    [
      addFileToMap,
      onMediaUpdate,
      onTradeUpdate,
      removeFileFromMap,
      showNotification,
      tradeItem.id,
      tradeItem.note,
      updateProgress,
    ]
  )

  const handleFiles = useCallback(
    (files: (File | null)[]) => {
      files.forEach((file) => {
        if (!file) return
        if (file.size > FILE_PARAMS.maxSize) {
          return showNotification({
            text: 'File size should be less than 5MB',
            type: 'error',
          })
        }
        if (!FILE_PARAMS.allowedFormats.includes(file.type)) {
          return showNotification({
            text: 'File should be an JPG or PNG image',
            type: 'error',
          })
        }
        uploadImage(file)
      })
    },
    [showNotification, uploadImage]
  )

  return { uploadingList, handleFiles }
}
