import './ShareJournalDialog.scss'

import { IconsSrc } from 'core/assets'
import { Contact, JournalDetailedInfo, JournalInfo } from 'core/types'
import { useCallback, useState } from 'react'
import { Button } from 'shared/Button'
import { Modal, ModalController, useModal } from 'shared/Modal'
import { ModalLayout } from 'shared/ModalLayout'
import { useNotifications } from 'shared/Notifications'

type ShareJournalDialogProps = {
  controller: ModalController<any>
  journal?: JournalInfo | JournalDetailedInfo | null
  isLoading: boolean
  onRevokeAccess: (email: string) => Promise<any>
  onShare: (email: string) => Promise<void>
}
export function ShareJournalDialog({
  controller,
  journal,
  isLoading,
  onRevokeAccess,
  onShare,
}: Readonly<ShareJournalDialogProps>) {
  const [userString, setUserString] = useState('')
  const { showNotification } = useNotifications()

  const confirmRevoke = useModal()
  const openConfirmRevokeModal = async (email: string) => {
    const shouldRevoke = await confirmRevoke.open()
    if (shouldRevoke) {
      try {
        await onRevokeAccess(email)
        confirmRevoke.close()
      } catch (err) {}
    }
  }
  const shareJournal = async () => {
    if (!journal) return
    try {
      await onShare(userString)
      setUserString('')
    } catch (err) {}
  }
  const copyLink = useCallback(async () => {
    const link = window.location.origin + '/home/dashboard?journal_id=' + journal?.id
    try {
      await navigator.clipboard.writeText(link)
      showNotification({ text: 'Link copied', type: 'success' })
    } catch (err) {
      showNotification({ text: 'Failed to copy link', type: 'error' })
    }
  }, [showNotification, journal])

  return (
    <>
      <ModalLayout className="ShareJournalDialog">
        <ModalLayout.CloseButton onClick={() => controller.close()} />
        <ModalLayout.Heading>
          Share {journal?.journalName ? `"${journal?.journalName}"` : 'journal'}
        </ModalLayout.Heading>
        <ModalLayout.FormControl label="Email">
          <input
            type="text"
            placeholder="example@email.com"
            value={userString}
            onChange={(e) => setUserString(e.target.value)}
          />
          <div className="share-controls">
            <Button
              color="primary"
              isLoading={isLoading}
              disabled={userString.trim() === ''}
              onClick={() => shareJournal()}
            >
              <img src={IconsSrc.Share} alt="Share" className="icon" />
              Share
            </Button>
            <Button appearance="text" onClick={() => copyLink()}>
              <img src={IconsSrc.Link} alt="Link" className="icon" />
              Copy link
            </Button>
          </div>
          <div className="hint text-description-regular color-secondary-l3">
            The journal can only be viewed by users who has access
          </div>
        </ModalLayout.FormControl>
        {journal && (
          <AccessControlBlock journal={journal} onRevokeAccess={openConfirmRevokeModal} />
        )}
      </ModalLayout>
      <Modal controller={confirmRevoke}>
        <ModalLayout>
          <ModalLayout.CloseButton onClick={() => confirmRevoke.close()} />
          <ModalLayout.Heading>Revoke access</ModalLayout.Heading>
          <ModalLayout.Description>
            Are you sure you want to revoke access to the journal for this user?
          </ModalLayout.Description>
          <ModalLayout.Buttons>
            <Button onClick={() => confirmRevoke.close(false)}>Cancel</Button>
            <Button
              color="error"
              onClick={() => confirmRevoke.resolveWithoutClosing(true)}
              isLoading={isLoading}
            >
              Revoke
            </Button>
          </ModalLayout.Buttons>
        </ModalLayout>
      </Modal>
    </>
  )
}

type AccessControlBlockProps = {
  journal: JournalInfo | JournalDetailedInfo
  onRevokeAccess: (email: string) => void
}
function AccessControlBlock({ journal, onRevokeAccess }: Readonly<AccessControlBlockProps>) {
  const sharedUsers = journal.sharedWith || []

  return (
    <div className="AccessControlBlock">
      <div className="text-big-bold">Who has access</div>
      <div className="list">
        <AccessControlEntry isOwner contact={journal.owner} />
        {sharedUsers.map((contact) => (
          <AccessControlEntry
            key={contact.userEmail}
            contact={contact}
            onRevokeAccess={() => onRevokeAccess(contact.userEmail)}
          />
        ))}
      </div>
    </div>
  )
}

type AccessControlEntryProps = {
  isOwner?: boolean
  contact: Contact
  onRevokeAccess?: () => void
}
function AccessControlEntry({
  isOwner,
  contact,
  onRevokeAccess,
}: Readonly<AccessControlEntryProps>) {
  return (
    <div className="AccessControlEntry">
      <div className="contact-info">{contact.userEmail}</div>
      {isOwner ? (
        <Button appearance="text" size="small" disabled>
          Owner
        </Button>
      ) : (
        <Button
          size="small"
          onClick={() => onRevokeAccess && onRevokeAccess()}
          appearance="link"
          color="error"
        >
          Revoke access
        </Button>
      )}
    </div>
  )
}
