import { AddJournalDialog } from 'core/contexts/JournalsApiContext/dialogs/AddJournalDialog'
import { EditJournalModal } from 'core/contexts/JournalsApiContext/dialogs/EditJournalModal'
import { useCallback, useMemo, useState } from 'react'
import { Modal, useModal } from 'shared/Modal'
import { JournalsApiContextData } from './models'
import { JournalsApiContext } from './JournalsApiContext'
import { ShareJournalDialog } from './dialogs/ShareJournalDialog'
import { useJournalsAll } from 'core/hooks/useJournalsAll'
import {
  useJournalDeleting,
  useJournalsEditing,
  useJournalSharing,
  useJournalsRemovingAccess,
  useUpdateJournals,
} from './hooks'
import { ModalLayout } from 'shared/ModalLayout'
import { Button } from 'shared/Button'

export function JournalsApiContextProvider({ children }: any) {
  const [selectedJournalId, setSelectedJournalId] = useState<string | null>(null)
  const journalsAll = useJournalsAll()
  // Current journal "delete", "update" etc modals work with. Has nothing to do with filters
  const selectedJournal = useMemo(() => {
    if (!selectedJournalId) return null
    return journalsAll.data?.find((j) => j.id === selectedJournalId) ?? null
  }, [selectedJournalId, journalsAll])

  const addModal = useModal()
  const openAddDialog = useCallback(() => {
    addModal.open()
  }, [addModal])

  const {
    editModal,
    editSelectedJournal,
    isLoading: isEditingLoading,
    openEditModal: openEditDialog,
  } = useJournalsEditing(setSelectedJournalId, selectedJournal)

  const {
    revokeAccessForMe,
    confirmRevokeAccessForMeModal,
    isLoading: isRemoveAccessForMeLoading,
    openRevokeAccessForMeModal,
  } = useJournalsRemovingAccess(setSelectedJournalId, selectedJournal)

  const {
    openShareModal: openShareDialog,
    revokeAccess,
    shareModal,
    shareSelectedJournal,
    isLoading: isSharingLoading,
  } = useJournalSharing(setSelectedJournalId, selectedJournal)

  const {
    deleteModal,
    deleteSelectedJournal,
    isLoading: isDeletingLoading,
    openDeleteModal: openDeleteDialog,
  } = useJournalDeleting(setSelectedJournalId, selectedJournal)

  const updateJournals = useUpdateJournals()

  const providerValue = useMemo(
    () =>
      ({
        updateJournals,
        openAddDialog,
        openEditDialog,
        openDeleteDialog,
        openShareDialog,
        openRevokeAccessForMeModal,
      } satisfies JournalsApiContextData),
    [
      updateJournals,
      openAddDialog,
      openEditDialog,
      openDeleteDialog,
      openShareDialog,
      openRevokeAccessForMeModal,
    ]
  )

  return (
    <JournalsApiContext.Provider value={providerValue}>
      <Modal controller={shareModal}>
        <ShareJournalDialog
          isLoading={isSharingLoading}
          controller={shareModal}
          journal={selectedJournal}
          onShare={(email) => shareSelectedJournal(email)}
          onRevokeAccess={(email) => revokeAccess(email)}
        />
      </Modal>
      <Modal controller={addModal}>
        <AddJournalDialog controller={addModal} />
      </Modal>
      <Modal controller={editModal}>
        <EditJournalModal
          journal={selectedJournal}
          controller={editModal}
          onEdit={(name, type) => editSelectedJournal(name, type)}
          isLoading={isEditingLoading}
        ></EditJournalModal>
      </Modal>
      <Modal controller={deleteModal}>
        <ModalLayout>
          <ModalLayout.Heading>Delete journal</ModalLayout.Heading>
          <ModalLayout.Description>
            Once removed, this action cannot be undone. The user will lose access to the journal and
            any associated data.
          </ModalLayout.Description>
          <ModalLayout.Buttons>
            <Button
              appearance="solid"
              disabled={isDeletingLoading}
              onClick={() => deleteModal.close()}
            >
              Cancel
            </Button>
            <Button
              appearance="solid"
              color="error"
              isLoading={isDeletingLoading}
              onClick={() => deleteSelectedJournal()}
            >
              Remove
            </Button>
          </ModalLayout.Buttons>
        </ModalLayout>
      </Modal>
      <Modal controller={confirmRevokeAccessForMeModal}>
        <ModalLayout>
          <ModalLayout.Heading>Remove from shared</ModalLayout.Heading>
          <ModalLayout.Description>
            Are you sure you want to remove your access to{' '}
            <strong>{selectedJournal?.journalName}</strong>
          </ModalLayout.Description>
          <ModalLayout.Buttons>
            <Button onClick={() => confirmRevokeAccessForMeModal.close()}>Cancel</Button>
            <Button
              isLoading={isRemoveAccessForMeLoading}
              onClick={() => revokeAccessForMe()}
              color="error"
            >
              Remove
            </Button>
          </ModalLayout.Buttons>
        </ModalLayout>
      </Modal>
      {children}
    </JournalsApiContext.Provider>
  )
}
